@layer components {
  [data-coco][data-component="cb-editable-slide"] {
    /*
     * slide-padding-lg at max (1480px): 240px (16.2%)
     * slide-padding-lg at min (320px): 16.2% x 320px = 52px
     */
    --slide-padding-lg: clamp(52px, 16.2cqw, 240px);

    /*
     * slide-padding-md at max (1480px): 120px (8.1%)
     * slide-padding-md at min (320px): 8.1% x 320px = 26px
     */
    --slide-padding-md: clamp(26px, 8.1cqw, 120px);

    /*
     * slide-padding-sm at max (1480px): 80px (5.4%)
     * slide-padding-sm at min (320px): 5.4% x 320px = 17px
     */
    --slide-padding-sm: max(24px, clamp(17px, 5.4cqw, 80px));

    /* Visually-adjusted small padding size for grid gap and header spacing */
    --slide-padding-sm-internal: max(20px, clamp(16px, 4.9cqw, 72px));

    /*
     * Small title font size at max (1480px): 64px (4.3%)
     * Small title font size at min (320px): 4.3% x 320px = 13.8px
     */
    --title-font-size: max(
      32px,
      clamp(13.8px, 4.3cqw, theme(fontSize.display-3))
    );

    /*
     * Text font size at max (1480px): 24px / 1.62%
     * Text font size at min (320px): 1.62% x 320px = 5.2px
     */
    --text-font-size: max(12px, clamp(6.1px, 1.86cqw, 28px));

    /* ------------- */

    @apply relative rounded-md @container;
    @apply bg-white bg-no-repeat bg-cover bg-center;

    min-width: 320px;

    .slide-aspect {
      @apply @slide-fluid:aspect-slide min-h-[62.5cqw] grid grid-cols-1 grid-rows-1;
    }

    .slide-container {
      @apply @slide-fluid:absolute @slide-fluid:inset-0 overflow-hidden;
      @apply px-6 py-10 @slide-fluid:px-[var(--slide-padding-md)] @slide-fluid:py-[var(--slide-padding-md)];
    }

    .slide-layout {
      @apply @slide-fluid:grid h-full w-full;
    }

    .slide-header,
    .slide-text,
    .slide-media {
      @apply hidden;
    }

    .slide-group {
      @apply contents;
    }

    .slide-header {
      @apply w-full;
    }

    .slide-title {
      @apply font-bold text-current w-full;
      overflow-wrap: anywhere;
      line-height: 1.1;
      font-size: var(--title-font-size);
    }

    .slide-text {
      @apply text-current text-base w-full overflow-hidden;
      line-height: 1.5;
    }

    .slide-media {
      @apply w-full text-current relative text-base;
    }

    @container (min-width: 700px) {
      .slide-text,
      .slide-media {
        font-size: var(--text-font-size);
      }
    }

    .slide-media-wrapper {
      @apply @slide-fluid:absolute @slide-fluid:inset-0 @slide-fluid:w-full @slide-fluid:h-full;

      img {
        @apply w-full h-full object-contain object-center;
      }
    }

    /* layouts */

    &[data-layout="one-col-title"] {
      /*
       * Title font size at max (1480px): 80px (5.4%)
       * Title font size at min (320px): 5.4% x 320px = 17px
       */
      --title-font-size: max(
        38px,
        clamp(17px, 5.4cqw, theme(fontSize.display-2))
      );

      /*
       * Text font size at max (1480px): 32px / 2.16%
       * Text font size at min (320px): 2.16% x 320px = 7px
       */
      --text-font-size: max(
        15px,
        clamp(7px, 2.16cqw, theme(fontSize.subheading-1))
      );

      .slide-container {
        @apply py-12 @slide-fluid:px-[var(--slide-padding-lg)] @slide-fluid:py-[var(--slide-padding-md)];
      }

      .slide-layout {
        @apply mx-auto max-w-[475px] @slide-fluid:max-w-full;
        grid-template-areas: "content";
      }

      [data-role="group-primary-text"] {
        @apply flex flex-col items-center justify-center text-center h-full;
        grid-area: content;
      }

      [data-role="header"],
      [data-role="text-primary"] {
        @apply block;
      }

      .slide-text {
        line-height: 1.4;
      }

      &.with-title .slide-text {
        margin-top: 0.875em;
      }
    }

    &[data-layout="one-col-text"] {
      .slide-container {
        @apply py-10 @slide-fluid:py-[var(--slide-padding-sm)];
      }

      .slide-layout {
        @apply @slide-fluid:py-[4.8cqw];
        grid-template-areas: "content";
        grid-template-rows: auto;
      }

      &.with-title .slide-layout {
        @apply py-0;
        grid-template-areas:
          "header"
          "content";
        grid-template-rows: min-content auto;
        grid-gap: var(--slide-padding-sm-internal);
      }

      [data-role="header"],
      [data-role="text-primary"] {
        @apply block;
      }

      [data-role="header"] {
        grid-area: header;
        @apply mb-5 @slide-fluid:mb-0;
      }

      [data-role="text-primary"] {
        grid-area: content;
      }
    }

    &[data-layout="one-col-media"] {
      .slide-container {
        @apply p-6 @slide-fluid:p-[var(--slide-padding-sm)];
      }

      .slide-layout {
        grid-template-areas: "content";
        grid-template-rows: 100%;
      }

      [data-role="media-primary"] {
        @apply block h-full;
        grid-area: content;
      }

      .slide-media-wrapper {
        @apply h-full;
      }
    }

    &[data-layout="two-col-mixed"] {
      .slide-container {
        @apply py-10 @slide-fluid:p-[var(--slide-padding-md)];
      }

      .slide-layout {
        grid-template-areas: "text media";
        grid-template-rows: 100%;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--slide-padding-sm-internal);
      }

      [data-role="header"],
      [data-role="text-primary"],
      [data-role="media-primary"],
      [data-role="group-primary-text"] {
        @apply block;
      }

      [data-role="group-primary-text"] {
        grid-area: text;
        @apply @slide-fluid:flex @slide-fluid:flex-col @slide-fluid:justify-center @slide-fluid:h-full;
      }

      [data-role="media-primary"] {
        @apply mt-6 @slide-fluid:mt-0;
        grid-area: media;
      }

      &.with-title .slide-text {
        margin-top: 0.875em;
      }
    }

    &[data-layout="two-col-text"] {
      .slide-container {
        @apply py-10 @slide-fluid:p-[var(--slide-padding-sm)];
      }

      .slide-layout {
        @apply @slide-fluid:py-[4.8cqw];
        grid-template-areas: "primary secondary";
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--slide-padding-sm-internal);
      }

      &.with-title .slide-layout {
        @apply py-0;
        grid-template-areas:
          "header header"
          "primary secondary";
        grid-template-rows: min-content auto;
      }

      [data-role="header"],
      [data-role="text-primary"],
      [data-role="text-secondary"] {
        @apply block;
      }

      [data-role="header"] {
        grid-area: header;
      }

      [data-role="text-primary"] {
        grid-area: primary;
      }

      [data-role="text-secondary"] {
        grid-area: secondary;
      }

      .slide-header,
      [data-role="text-primary"] {
        @apply mb-6 @slide-fluid:mb-0;
      }
    }

    &[data-layout="two-col-media"] {
      .slide-container {
        @apply py-10 @slide-fluid:p-[var(--slide-padding-sm)];
      }

      .slide-layout {
        @apply @slide-fluid:py-[4.8cqw];
        grid-template-areas: "primary secondary";
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--slide-padding-sm-internal);
      }

      &.with-title .slide-layout {
        @apply py-0;
        grid-template-areas:
          "header header"
          "primary secondary";
        grid-template-rows: min-content auto;
      }

      [data-role="header"],
      [data-role="media-primary"],
      [data-role="media-secondary"] {
        @apply block;
      }

      [data-role="header"] {
        grid-area: header;
      }

      [data-role="media-primary"] {
        grid-area: primary;
      }

      [data-role="media-secondary"] {
        grid-area: secondary;
      }

      .slide-header,
      [data-role="media-primary"] {
        @apply mb-6 @slide-fluid:mb-0;
      }
    }
  }
}
