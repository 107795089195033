@layer components {
  [data-coco][data-component="cb-nav-drawer"] {
    @apply pointer-events-none fixed top-14 left-0 bottom-0 w-screen overflow-hidden;
    height: var(--app-height);

    .nav-drawer-overlay {
      @apply pointer-events-none absolute inset-0 bg-black opacity-0 transition-opacity;
    }

    .nav-drawer-content {
      @apply absolute top-0 right-0 bottom-0 bg-white overflow-auto translate-x-full transition-transform py-4;
      max-width: 340px;
      min-width: 280px;
      width: fit-content;
    }

    .nav-drawer-item:last-child {
      @apply mb-10;
    }

    .nav-drawer-button {
      @apply w-full text-content-dark-2 hover:text-content-dark-1 hover:bg-background-light-3;

      .button-inner {
        @apply mx-0 px-4 py-3;
      }

      .button-label {
        @apply font-normal;
      }
    }

    .nav-drawer-heading {
      @apply mt-4 mb-2 mx-4 border-b text-content-dark-1/50 border-coco-gray-300 pb-3;
    }

    .nav-drawer-divider {
      @apply h-0 border-t border-coco-gray-300 my-3;
    }

    [data-component="notice"] {
      @apply mb-4;
    }

    .drawer-open & {
      @apply pointer-events-auto;

      .nav-drawer-overlay {
        @apply pointer-events-auto opacity-60;
        backdrop-filter: blur(2px);
      }

      .nav-drawer-content {
        @apply pointer-events-auto translate-x-0 shadow-2xl;
      }
    }
  }
}
