@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-body text-content-dark-1;
    scroll-behavior: smooth;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }

  html.has-js .no-js {
    display: none !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-display;
  }

  a {
    @apply text-current no-underline;
  }

  p a {
    text-decoration: underline;
  }

  a[disabled] {
    pointer-events: none;
  }

  [data-cloak] {
    display: none !important;
  }
}
