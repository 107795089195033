@layer components {
  [data-coco][data-component="cb-plan-picker"] {
    @apply @container;

    .plan-picker-controls {
      @apply space-y-4;

      [data-component="option-bar"] {
        @apply mx-auto;
      }

      .plan-picker-view-toggle {
        @apply hidden;
      }
    }

    .plan-picker-slider {
      @apply relative;
    }

    .plan-picker-slider-container {
      @apply overflow-x-clip;
    }

    .plan-picker-plans {
      @apply grid gap-y-8 grid-cols-1 transition-transform duration-300;
    }

    .plan-picker-view-toggle-mobile .coco-button {
      @apply mx-auto;
    }

    .plan-picker-pagination {
      @apply hidden;
    }

    .plan-picker-footer {
      @apply p-4 bg-background-light-3 border border-gray-transparent-100 rounded-2xl shadow-md flex flex-col items-center justify-center gap-4 my-2 text-center;
    }

    [data-component="cb-plan-card"] {
      @apply h-full;
    }

    &.slider-active {
      .plan-picker-pagination {
        @apply flex items-center justify-between absolute top-1/2 left-0 right-0 -translate-y-1/2;
      }
    }

    @container (min-width: 420px) {
      .plan-picker-controls {
        @apply relative space-y-0;

        .plan-picker-currency {
          @apply absolute left-0 top-0 mx-0;
        }

        .plan-picker-currency
          + .plan-picker-interval
          > [data-component="option-bar"] {
          @apply mr-0;
        }
      }

      .plan-picker-plans {
        @apply grid gap-x-4 grid-cols-2;
      }

      .plan-picker-footer {
        @apply flex-row my-0 text-left;
      }
    }

    @container (min-width: 984px) {
      .plan-picker-controls {
        .plan-picker-interval > [data-component="option-bar"] {
          @apply !mx-auto;
        }

        .plan-picker-view-toggle {
          @apply block absolute right-0 top-0 mx-0;
        }
      }

      .plan-picker-plans {
        @apply gap-x-6 grid-cols-4;
      }

      .plan-picker-view-toggle-mobile {
        @apply hidden;
      }

      &.slider-active {
        .plan-picker-plans {
          grid-template-columns: repeat(20, calc((100% - 4.5rem) / 4));
        }
      }

      &.slider-active {
        .plan-picker-slider {
          padding-left: 72px;
          padding-right: 72px;
        }
      }
    }

    @container (min-width: 1200px) {
      .plan-picker-plans {
        @apply gap-x-8;
      }

      &.slider-active {
        .plan-picker-plans {
          grid-template-columns: repeat(20, calc((100% - 6rem) / 4));
        }
      }
    }

    @media (min-width: 1540px) {
      &.slider-active {
        .plan-picker-pagination {
          @apply flex items-center justify-between absolute top-1/2 left-0 right-0 -translate-y-1/2;
          margin-left: -72px;
          margin-right: -72px;
          width: calc(100% + 144px);
        }

        .plan-picker-slider {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
