@layer components {
  [data-coco][data-component="cb-color-field"] {
    position: relative;

    > input {
      @apply pl-10;
    }

    .color-field-indicator {
      position: absolute;
      display: block;
      @apply top-2.5 left-2.5 mt-px rounded w-6 h-6;
    }

    &[aria-expanded="true"] {
      > input {
        @apply !border-content-primary !ring-content-primary !ring-1;
      }
    }
  }
}
