@layer components {
  [data-coco][data-component="cb-image-field"] {
    width: 100%;
    position: relative;

    .image-field-container {
      @apply bg-coco-gray-200 transition-all border-2 border-gray-transparent-100;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    .image-field-inputs {
      input {
        display: none;
      }

      input[type="file"] {
        display: block;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        z-index: 2;
      }
    }

    .image-field-preview {
      width: 100%;
      display: block;
    }

    &.uploading {
      .image-field-preview {
        opacity: 0.1;
      }
    }

    label {
      line-height: 1.2;
      display: block;
      margin-top: 0.5rem;
      font-size: 13px;
      text-align: center;
      max-width: 140px;
      opacity: 0.8;
      margin-inline: auto;
    }

    .image-field-upload-progress {
      @apply p-2;
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .coco-icon {
        width: 20%;
        height: auto;
        max-width: 2rem;
        min-width: 1rem;
        margin-inline: auto;
      }
    }

    .image-field-placeholder {
      @apply p-2;
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > div {
        width: 100%;
        text-align: center;
      }

      .coco-icon {
        width: 40%;
        height: auto;
        max-width: 5rem;
        min-width: 24px;
        opacity: 0.2;
        margin-inline: auto;
      }
    }

    .image-field-clear {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(25%) translateY(-25%);
      z-index: 2;
    }

    &.dragging {
      .image-field-container {
        @apply bg-positive-100 border-content-primary-inverse-vivid;
      }

      .image-field-placeholder {
        @apply text-positive-600;
      }

      &.invalid {
        .image-field-container {
          @apply bg-negative-100 border-content-negative;
        }

        .image-field-placeholder {
          @apply text-negative-800;
        }
      }
    }

    &:not([data-aspect="auto"]) {
      .image-field-preview {
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      &[data-fit="cover"] .image-field-preview {
        object-fit: cover;
      }
    }

    &[data-aspect="auto"]:not(.with-image) {
      aspect-ratio: 1 / 1;
    }

    &[data-aspect="square"] {
      aspect-ratio: 1 / 1;

      &[data-round="true"] {
        .image-field-container {
          border-radius: 100%;
        }

        .image-field-clear {
          top: 50%;
          transform: translateX(40%) translateY(-50%);
        }
      }
    }

    &[data-aspect="four-three"],
    &[data-aspect="four_three"] {
      aspect-ratio: 4 / 3;
    }

    &[data-aspect="three-two"],
    &[data-aspect="three_two"] {
      aspect-ratio: 3 / 2;
    }

    &[data-aspect="two-one"],
    &[data-aspect="two_one"] {
      aspect-ratio: 2 / 1;
    }
  }
}
