@layer components {
  [data-coco][data-component="cb-contact-callout"] {
    @apply @container bg-background-primary-light rounded-2xl;

    .callout-container {
      @apply w-full p-6 gap-x-6;
    }

    .callout-avatar {
      @apply flex-none self-start ml-6 float-right;
      width: 100px;
    }

    .callout-blurb {
      @apply subheading-4 space-y-2;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply branded-heading-6;
      }
    }

    .callout-action {
      @apply mt-4;
    }

    .callout-tel,
    .callout-number {
      @apply flex items-center;
    }

    .callout-tel {
      @apply mt-4 gap-x-4;

      .coco-icon {
        @apply hidden;
      }
    }

    .callout-numbers {
      @apply gap-x-10;
    }

    .callout-number {
      @apply gap-x-2 whitespace-nowrap;
    }

    @container (min-width: 600px) {
      .callout-container {
        @apply flex items-center;
      }

      .callout-numbers {
        @apply flex items-center;
      }

      .callout-tel {
        .coco-icon {
          @apply flex;
        }
      }

      .callout-avatar {
        @apply float-none ml-0;
      }
    }

    @container (min-width: 800px) {
      .callout-container {
        @apply gap-x-6;
      }

      .callout-main {
        @apply flex items-center gap-x-6 w-full;
      }

      .callout-action {
        @apply flex-none ml-auto mt-0;
        width: min-content;
      }

      .callout-avatar {
        @apply flex-none self-center;
        width: 120px;
      }
    }

    @container (min-width: 1100px) {
      .callout-container {
        @apply px-10 gap-x-10;
      }

      .callout-main {
        @apply gap-x-10;
      }

      .callout-avatar {
        width: 140px;
      }

      .callout-blurb {
        @apply subheading-3;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          @apply branded-heading-5;
        }
      }
    }
  }
}
