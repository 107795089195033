@layer components {
  .clip-slat {
    @apply relative bg-white p-1 py-2 border border-gray-400 rounded-xl transition flex items-center w-full shadow-sm;

    .button-group-buttons {
      @apply !gap-x-0;

      .coco-button:not(.tippy-content .coco-button) {
        @apply text-coco-gray-500 hover:text-coco-gray-800;
      }
    }
  }

  .clip-slat-handle {
    @apply ml-1 mr-2 self-stretch flex items-center cursor-pointer;
  }

  .clip-slat-body {
    @apply py-1 flex items-center w-full relative;
  }

  .clip-slat-thumb-cat {
    font-size: 11px;
    @apply flex-none w-20 leading-none relative;
  }

  .clip-slat-thumb {
    @apply bg-gray-200 rounded-md overflow-hidden;
  }

  .clip-slat-cat {
    @apply bg-white p-1 absolute -top-1 -right-1 rounded-lg;
  }

  .clip-slat-details {
    @apply leading-none block overflow-hidden ml-3 pr-3 mr-auto;
  }

  .clip-slat-title {
    @apply font-bold text-sm leading-none truncate;
  }

  .clip-slat-subtitle {
    @apply block text-gray-700 text-sm truncate-2-lines leading-tight mt-0.5;
  }

  .clip-slat-pubdate {
    @apply pr-4 text-gray-600 whitespace-nowrap text-sm;
  }

  .clip-slat-metrics {
    @apply ml-1 hidden lg:flex border-l border-gray-400 cursor-default items-center divide-x divide-gray-400;
  }
}
