@layer components {
  [data-coco][data-component="cb-import-feedback"] {
    .import-feedback-warning {
      @apply mt-4 mb-8;
    }

    .import-feedback-duplicate-urls {
      width: 100%;
      @apply text-sm text-content-dark-3 mt-2;
    }
  }
}
