@layer components {
  [data-coco][data-component="cb-slide-editor"] {
    .editor-inputs {
      @apply hidden;
    }

    .editor-toolbar {
      @apply mb-4 xl:mb-6;
    }

    .editor-form form {
      @apply hidden;
    }

    .slide-media {
      @apply relative;
    }

    .editor-slide {
      @apply shadow-md;

      textarea {
        @apply flex-none w-full;
      }
    }

    .editor-foreground-image-uploader {
      @apply h-full w-full relative;

      .editor-foreground-image {
        @apply h-full w-full relative transition-[outline-color] duration-100;

        &:hover {
          @apply outline outline-1 outline-transparent outline-offset-0;
        }

        img {
          @apply w-full h-full object-contain;
        }
      }

      .editor-image-upload-placeholder {
        @apply inset-0 flex flex-col items-center justify-center text-center aspect-[4/3] @slide-fluid:aspect-auto @slide-fluid:absolute;
        @apply outline outline-1 outline-transparent outline-offset-0;
        background-color: rgba(255, 255, 255, 0.1);

        .coco-icon {
          @apply text-current;
          width: 3em;
          height: 3em;
          margin-bottom: 0.6em;
        }

        .coco-icon,
        .editor-image-upload-placeholder-text {
          @apply opacity-50;
        }
      }

      .editor-image-upload-toolbar {
        @apply absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-100;
      }

      &:hover .editor-image-upload-toolbar {
        @apply opacity-100;
      }
    }

    .coco-button.editor-saving {
      @apply cursor-not-allowed;
    }

    .editor-thumbnail-wrapper {
      @apply w-0 overflow-hidden relative;
    }

    .editor-thumbnail {
      @apply absolute w-[780px] top-0 z-[-1000] pointer-events-none;

      .slide-text {
        @apply !overflow-visible;

        span {
          display: block;
          overflow: visible;
        }
      }
    }

    .editor-thumbnail-image {
      @apply w-full h-full bg-contain bg-no-repeat bg-center;
    }

    .editor-icon-bg-color {
      @apply stroke-content-dark-1 fill-current;
      stroke-width: 2px;
    }

    .slide-title,
    .slide-text {
      @apply outline outline-1 outline-transparent outline-offset-0 transition-[outline-color] duration-100;

      textarea::placeholder {
        @apply italic;
      }
    }

    .slide-bg-light {
      .editor-foreground-image,
      .editor-image-upload-placeholder {
        outline-color: rgba(0, 0, 0, 0.3) !important;
      }

      .editor-image-upload-placeholder {
        @apply text-content-dark-1;
      }

      .slide-title,
      .slide-text {
        &:hover,
        &:focus {
          outline-color: rgba(0, 0, 0, 0.3);
        }
      }

      &[data-placeholders~="title"] [data-role="title"],
      &[data-placeholders~="text1"] [data-role="text-primary"],
      &[data-placeholders~="text2"] [data-role="text-secondary"] {
        outline-color: rgba(0, 0, 0, 0.3) !important;
      }
    }

    .slide-bg-dark {
      .editor-foreground-image,
      .editor-image-upload-placeholder {
        outline-color: rgba(255, 255, 255, 0.3) !important;
      }

      .editor-image-upload-placeholder {
        @apply text-content-light-1;
      }

      .slide-title,
      .slide-text {
        &:hover,
        &:focus {
          outline-color: rgba(255, 255, 255, 0.3);
        }
      }

      &[data-placeholders~="title"] [data-role="title"],
      &[data-placeholders~="text1"] [data-role="text-primary"],
      &[data-placeholders~="text2"] [data-role="text-secondary"] {
        outline-color: rgba(255, 255, 255, 0.3) !important;
      }
    }
  }
}
