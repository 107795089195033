@layer components {
  [data-coco][data-component="cb-progress-bar"] {
    .progress-bar-info {
      display: flex;
      align-items: center;
      @apply mb-2;
    }

    .progress-bar-label {
      @apply pr-4 gap-x-1.5;
      display: flex;
      align-items: center;
      overflow: hidden;
      min-width: 1%;
      font-weight: 600;

      .coco-icon {
        flex: none;
      }

      span {
        min-width: 1%;
        @apply truncate text-label-md;
      }
    }

    &.complete {
      .progress-bar-label {
        @apply text-content-primary;
      }
    }

    .progress-bar-percentage {
      @apply text-label-md text-content-primary;
      font-weight: 600;
      flex: none;
      margin-left: auto;
    }

    .progress-bar-meter {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      width: 100%;
      display: block;
      border-radius: 8px;
      @apply bg-background-primary-light h-2 transition-all;
    }

    progress[value]::-webkit-progress-bar {
      border-radius: 8px;
      @apply bg-background-primary-light;
    }

    progress[value]::-webkit-progress-value {
      border-radius: 8px;
      @apply bg-content-primary;
    }

    progress[value]::-moz-progress-bar {
      border-radius: 8px;
      @apply bg-content-primary;
    }

    .progress-bar-message {
      @apply mt-2 text-para-sm text-content-dark-3;
    }
  }
}
