@layer components {
  [data-coco][data-component="cb-sidebar-nav-item"] {
    @apply bg-background-dark-2 text-content-dark-muted text-center no-underline cursor-pointer;
    @apply flex items-center justify-center w-full flex-col;

    &.active,
    &:hover {
      @apply bg-background-dark-1 text-content-light-1;
    }

    .nav-item-icon {
      @apply block mx-auto transition-colors;
    }

    .nav-item-label {
      @apply block font-semibold transition-colors cursor-pointer;
    }

    .nav-item-action-button {
      @apply bg-content-primary-inverse-vivid transition-all text-content-light-1;
      @apply flex items-center justify-center mx-auto rounded-full cursor-pointer;
    }

    @media screen(max-sm) {
      @apply app-sidebar-nav-horizontal-item;
    }

    @media screen(sm) and screen(max-xl) {
      @apply app-sidebar-nav-vertical-narrow-item;
    }

    @media screen(xl) {
      @apply app-sidebar-nav-vertical-wide-item;
    }

    @media screen(letterbox) {
      @apply app-sidebar-nav-vertical-narrow-item;
    }
  }
}

@layer utilities {
  .app-sidebar-nav-horizontal-item {
    .nav-item-label {
      @apply label-xxs mt-0.5;
    }

    .nav-item-icon .coco-icon {
      @apply w-6 h-6;
    }

    .nav-item-action-button {
      @apply h-12 w-12;

      .coco-icon {
        @apply h-6 w-6;
      }
    }
  }

  .app-sidebar-nav-vertical-item {
    .nav-item-action-button {
      &:hover {
        transform: scale(1.05);
      }
    }

    &[data-active="true"] {
      @apply bg-background-dark-3 hover:bg-background-dark-3 text-content-light-1;
    }

    &[data-emphasise="true"] {
      .nav-item-icon {
        @apply text-content-primary-inverse-vivid hover:text-content-primary-inverse-vivid;
      }
    }
  }

  .app-sidebar-nav-vertical-narrow-item {
    @apply app-sidebar-nav-vertical-item;
    @apply py-4;

    .nav-item-label {
      @apply label-xxs mt-1;
    }

    .nav-item-icon .coco-icon {
      @apply w-6 h-6;
    }

    .nav-item-action-button {
      @apply h-12 w-12;

      .coco-icon {
        @apply h-6 w-6;
      }
    }
  }

  .app-sidebar-nav-vertical-wide-item {
    @apply app-sidebar-nav-vertical-item;
    @apply py-5;

    .nav-item-label {
      @apply label-xs mt-1.5;
    }

    .nav-item-icon .coco-icon {
      @apply w-8 h-8;
    }

    .nav-item-action-button {
      height: 72px;
      width: 72px;

      .coco-icon {
        @apply h-10 w-10;
      }
    }
  }
}
