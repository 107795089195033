[data-partial="folders/folder"] {
  display: inline-flex;
  width: 100%;
  position: relative;
  align-items: center;
  color: var(--coco-colors-content-dark-1);
  border: 1px solid transparent;
  @apply gap-2 transition-all;
  @apply px-2 rounded-md;

  .folder-icon {
    position: relative;

    > .coco-icon {
      @apply w-8;
      @apply h-8;
      color: var(--coco-colors-content-dark-1);

      svg {
        stroke: var(--coco-colors-green-200) !important;
        fill: var(--coco-colors-green-100) !important;
        @apply transition-all;
      }

      img {
        width: 100%;
        height: 100%;
        flex: none;
      }
    }

    .folder-indicator {
      position: absolute;
      bottom: -1px;
      right: -2px;
      border: 2px solid #fff;
      width: 10px;
      height: 10px;
    }
  }

  .folder-badge {
    display: flex;
    align-items: center;
    width: min-content;
    font-size: 12px;
    line-height: 1;
    position: absolute;
    top: 0;
    border-radius: 12px;
    padding: 2px 8px 2px 6px;
    background-color: var(--coco-colors-blue-100);
    display: none;

    @apply gap-1 left-2 -translate-y-1/2;
  }

  .folder-link {
    position: absolute;
    display: block;
    inset: 0;
  }

  .folder-indicator {
    width: 6px;
    height: 6px;
    flex: none;
    border-radius: 100%;
    background-color: var(--coco-colors-content-primary);
  }

  .folder-name {
    display: block;
    font-weight: 600;
    color: var(--coco-colors-content-dark-1);
    @apply truncate label-sm;
  }

  .folder-items-count {
    @apply text-xs;
    color: var(--coco-colors-content-dark-3);
  }

  .folder-items-count-qualifier {
    display: none;
  }

  .folder-actions {
    margin-left: auto;
    position: relative;
    display: none;
  }

  .folder-pin {
    opacity: 0;
  }

  .folder-unpin {
    opacity: 1;
  }

  &[data-pinned="true"] {
    .folder-actions {
      display: block;
    }
  }

  &:hover {
    background-color: var(--coco-colors-gray-transparent-50);

    .folder-name {
      text-decoration: underline;
    }

    .folder-actions {
      display: block;
    }

    .folder-pin {
      opacity: 1;
    }

    .folder-icon > .coco-icon {
      svg {
        stroke: var(--coco-colors-green-300) !important;
      }
    }
  }

  &:not([data-partial="folders/list"] [data-partial="folders/folder"]) {
    @apply folder-sm;
  }
}

@layer utilities {
  .folder-sm {
    .folder-icon > .coco-icon {
      @apply w-5;
      @apply h-5;
    }

    .folder-items-count {
      display: inline-flex;

      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }
    }

    .folder-details {
      height: 38px;
      display: flex;
      align-items: center;
      min-width: 1%;
      overflow: hidden;
      @apply gap-1;
    }
  }

  .folder-lg {
    @apply !py-0.5;

    .folder-details {
      display: flex;
      flex-wrap: wrap;
      min-width: 1%;
      overflow: hidden;
      @apply gap-0.5 py-1.5;
    }

    .folder-details > span {
      display: block;
      width: 100%;
    }

    .folder-items-count-qualifier {
      display: inline;
    }

    .button-group-buttons {
      @apply gap-x-0;
    }
  }

  .folder-card {
    background-color: transparent;
    border: 1px solid var(--coco-colors-gray-transparent-100);
    @apply pr-2 pl-2 py-1;

    .folder-badge {
      display: flex;
    }

    .folder-icon .folder-indicator {
      display: none;
    }

    &:hover {
      @apply shadow-lg;

      .folder-name {
        text-decoration: none;
      }
    }
  }
}
