@layer components {
  [data-component="container"] {
    &.container-padded {
      @apply px-3 sm:px-6 md:px-8 lg:px-10 xl:px-11;
    }

    & > div {
      @apply w-full mx-auto;
    }

    &.container-constrained > div {
      @apply max-w-screen-xxl;
    }
  }
}
