.orderable-item {
  position: relative;

  .orderable-item-selector {
    position: absolute;
    z-index: 10;
  }

  .orderable-item-checkbox {
    line-height: 1;
    display: block;
  }
}
