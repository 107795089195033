@layer components {
  [data-coco][data-component="cb-pagination"] {
    @apply flex justify-center w-full;

    .pagination-nav {
      @apply flex-none inline-flex items-center rounded-xl border border-coco-gray-300 overflow-hidden divide-x divide-coco-gray-300;
      @apply bg-background-light-1 text-content-dark-2;
    }

    a {
      @apply block px-4 py-3 text-content-dark-2;
      @apply hover:text-content-dark-1 active:text-content-dark-1 hover:bg-background-light-2 active:bg-background-light-2 focus:outline-none;
    }

    .pagination-page {
      @apply block font-medium text-label-sm transition ease-in-out bg-background-light-1 text-content-dark-2;

      &.disabled {
        @apply block px-4 py-3 cursor-not-allowed text-content-dark-muted;
      }
    }

    .pagination-current {
      @apply block px-4 py-3 font-bold;
    }

    .pagination-gap {
      @apply block px-4 py-3;
    }
  }
}
