.clip-card-list {
  width: 100%;
  max-width: 100%;
  @apply @container;

  &[data-list-display-format="list"] .clip-card-list-items {
    @apply space-y-3;
  }

  &[data-list-display-format="grid"] .clip-card-list-items {
    display: grid;
    @apply grid-cols-1 @sm:grid-cols-2 @2xl:grid-cols-3 @5xl:grid-cols-4 @7xl:grid-cols-5;
    @apply gap-4;
  }
}
