@layer components {
  .link {
    @apply cursor-pointer;
  }

  .link-primary {
    @apply text-green-dark hover:text-green-darker;
  }

  .link-secondary {
    @apply text-gray-700 hover:text-gray-800;
  }

  .link-danger {
    @apply text-red-dark hover:text-red-darker;
  }
}
