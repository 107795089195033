[data-partial="folders/folder-toolbar"] {
  border-bottom: 1px solid var(--coco-colors-gray-transparent-200);
  @apply px-app py-2;

  .toolbar-container {
    margin-inline: auto;
    @apply max-w-screen-xxl;
  }

  .toolbar-dropdown {
    max-width: 280px;
    font-weight: normal;
    @apply py-5 px-6 text-para-sm space-y-2;
  }

  .share-status {
    border-color: transparent !important;
  }

  .share-status-indicator {
    border-radius: 100%;
    display: block;
    margin: auto;
    @apply w-3 h-3;
  }

  .share-status-public {
    background-color: var(--coco-colors-blue-100) !important;

    .share-status-indicator {
      background-color: var(--coco-colors-content-primary);
    }
  }

  .share-status-internal {
    background-color: var(--coco-colors-gray-transparent-50) !important;
    .share-status-indicator {
      background-color: var(--coco-colors-gray-transparent-200);
    }
  }

  .share-info {
    whitespace: no-wrap;
    @apply text-label-sm pl-2;

    a {
      color: var(--coco-colors-content-primary);
      text-decoration: underline;
    }
  }

  @media all and (max-width: 860px) {
    .share-info {
      display: none;
    }
  }
}
