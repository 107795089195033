.orderable-items {
  .orderable-items-toolbar {
    background: var(--coco-colors-background-light-2);
    z-index: 100;
    @apply py-2 px-4 -mx-4;

    &.is-pinned {
      box-shadow: 0px 15px 17px -16px rgba(0, 0, 0, 0.35);
    }
  }

  .orderable-items-list {
    @apply mt-2;
  }

  .orderable-items-pagination {
    @apply mt-8;
  }
}
