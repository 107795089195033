@layer components {
  [data-component="slide-card"] {
    @apply relative bg-white border border-gray-400 rounded-xl overflow-hidden transition flex flex-col shadow;

    & .slide-card-toolbar {
      @apply flex items-center flex-none;

      .button-group-buttons {
        @apply !gap-x-0;

        .coco-button:not(.tippy-content .coco-button) {
          @apply text-coco-gray-500 hover:text-coco-gray-800;
        }
      }
    }

    .clip-card-handle {
      @apply px-4 py-1 block;

      input[type="checkbox"] {
        @apply w-4 h-4;
      }
    }

    & .slide-card-body {
      @apply flex flex-col relative pb-3;
    }

    & .slide-card-details {
      @apply px-4 pt-3 border-t border-gray-400 flex items-start;
    }

    & .slide-card-title {
      @apply font-bold text-gray-1000 text-sm xl:text-base leading-none truncate;
    }

    & .slide-card-body .coco-icon {
      @apply text-gray-800 ml-auto opacity-70 mt-1;
    }

    & .slide-card-link {
      @apply absolute block inset-0;
    }
  }
}
