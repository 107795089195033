@layer components {
  [data-coco][data-component="cb-app-header"] {
    @apply @container;

    .header-content {
      @apply h-14 flex items-center bg-background-header px-app;
    }

    .header-logo {
      @apply flex-none mr-6;
    }

    .header-logo-no-wordmark {
      @apply h-7 xs:hidden translate-x-1;
    }

    .header-logo-full {
      @apply hidden xs:block h-7;
    }

    .header-links {
      @apply flex items-center w-full;
    }

    .header-link-group {
      @apply items-center gap-x-6;
    }

    .header-secondary {
      @apply ml-auto translate-x-2 sm:translate-x-[17px];
    }

    .header-mobile-controls {
      @apply flex lg:hidden items-center ml-auto translate-x-2 sm:translate-x-4 md:translate-x-6;
    }

    .coco-button[data-variant="highlighted"] {
      @apply text-amber-300;
    }

    &.with-nav-drawer {
      .header-link-group {
        @apply hidden lg:flex;
      }
    }
  }
}
