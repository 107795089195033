@layer components {
  .clip-card:not([data-partial]) {
    @apply relative bg-white border border-gray-400 rounded-xl overflow-hidden transition flex flex-col shadow;

    .clip-card-toolbar {
      @apply flex items-center flex-none;

      .button-group-buttons {
        @apply !gap-x-0;

        .coco-button:not(.tippy-content .coco-button) {
          @apply text-coco-gray-500 hover:text-coco-gray-800;
        }
      }
    }

    .clip-card-handle {
      @apply px-4 py-1 block;

      input[type="checkbox"] {
        @apply w-4 h-4;
      }
    }

    .clip-card-body {
      @apply flex flex-col pb-3 h-full;
    }

    .clip-card-details {
      @apply px-4 pt-3 border-t border-gray-400;
    }

    .clip-card-metrics {
      @apply px-4 pt-4 mt-auto flex items-center space-x-3 flex-none;
    }

    .clip-card-link {
      @apply absolute block inset-0;
    }
  }
}
