.folder-header {
  background-color: var(--folder-header-bg-color, #374151);

  .header-main {
    padding-block: 0.5em;
  }

  .header-content {
    padding-block: 0.166em;
  }

  &.is-light {
    color: var(--coco-colors-content-dark-1);

    .header-icon {
      color: var(--coco-colors-content-dark-1);
    }
  }

  &.is-dark {
    color: var(--coco-colors-content-light-1);

    .header-icon {
      color: var(--coco-colors-content-light-1);
    }

    .breadcrumb-divider {
      color: var(--coco-colors-content-light-1);
    }

    .breadcrumb-link {
      color: var(--coco-colors-content-light-1);
    }

    .breadcrumb-text {
      color: var(--coco-colors-content-light-muted);
    }
  }
}
