.highlights-list {
  width: 100%;
  max-width: 100%;
  @apply @container;

  &[data-list-display-format="list"] .highlights-list-items {
    @apply space-y-3;
  }

  &[data-list-display-format="grid"] .highlights-list-items {
    display: grid;
    @apply grid-cols-1 @2xl:grid-cols-2 @4xl:grid-cols-3;
    @apply gap-4 @2xl:gap-6 @4xl:gap-8;
  }
}
