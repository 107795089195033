@layer components {
  .montage {
    @apply overflow-hidden relative bg-opacity-80;
  }

  .montage-images {
    @apply flex flex-wrap py-10 -mx-20;
  }

  .montage-images > div {
    @apply relative py-2 px-2;
  }

  .montage-images-spaced > div {
    @apply book-lg:py-4 book-lg:px-3;
  }

  .montage-image {
    @apply rounded-2xl shadow-lg overflow-hidden;
  }

  .montage-bg {
    @apply absolute inset-0 opacity-70;
  }
}
