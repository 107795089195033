.metrics-summary-list-edit .metrics-summary-card {
  @apply hover:border-gray-500 hover:shadow-xl cursor-move;
}

.metrics-summary-list .metrics-summary-card [data-component-role="definition"] {
  @apply hidden;
}

.metrics-summary-list .metrics-summary-card:nth-child(n + 3) {
  @apply col-span-1 px-4 sm:px-6 md:px-4 lg:px-6 xl:px-10;

  & [data-component-role="value"] {
    @apply text-3xl sm:text-4xl lg:text-5xl xl:text-6xl text-gray-1000;
  }

  /* & [data-component-role="label"] span {
    @apply lg:text-lg;
  } */

  & [data-component-role="description"] {
    @apply lg:mt-8;
  }

  /* & [data-component-role="definition"] {
    @apply inline-flex;
  } */
}

.metrics-summary-list .metrics-summary-card.print-metric {
  @apply col-span-1 px-4 sm:px-6 md:px-4 lg:px-6 xl:px-10 !shadow-none;
  & [data-component-role="value"] {
    @apply text-4xl text-gray-1000;
  }
}
