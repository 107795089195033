[data-filestack-dropzone-target="dropzone"] .fsp-drop-pane__container {
  @apply bg-transparent border-none;
  z-index: 998;
}

[data-filestack-dropzone-target="dropzone"] .fsp-drop-pane__text {
  @apply hidden;
}

[data-filestack-dropzone-target="dropzone"] .fsp-notifications__container {
  @apply hidden;
}
