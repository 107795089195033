@layer components {
  label.required:after {
    content: "*";
    @apply text-content-negative ml-0.5;
  }

  .form-group label {
    @apply mb-1;

    &:last-child {
      @apply mb-0;
    }
  }

  .form-group .field-hint {
    @apply mt-1;
  }

  .form-group .field-error {
    @apply mb-1;
  }

  .form-group + .form-group {
    @apply mt-6;
  }

  .form-group + .form-actions,
  .form-group + .form-action,
  .form-group + .coco-button-wrapper > .form-action {
    @apply mt-7;
  }

  fieldset {
    @apply space-y-6;
  }

  .combo-field {
    .form-field,
    .ts-wrapper,
    .ts-control {
      position: relative;
      z-index: 3;
      @apply shadow-none !important;
    }

    .button {
      position: relative;
      z-index: 2;
      @apply border-content-dark-muted;
    }
  }

  .combo-field.combo-action-end {
    .form-field,
    .ts-wrapper,
    .ts-control {
      @apply rounded-r-none !important;
    }
    .button {
      @apply rounded-l-none -ml-1 !important;
    }
  }

  .combo-field.combo-action-start {
    .form-field,
    .ts-wrapper,
    .ts-control {
      @apply rounded-l-none !important;
    }
    .button {
      @apply rounded-r-none -mr-1 !important;
    }
  }

  .select[data-select-filterable-value="false"] + div .dropdown-input-wrap {
    @apply hidden !important;
  }

  .search-input-wrapper {
    &.with-icon {
      position: relative;
      .coco-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
      }

      input {
        padding-left: 34px !important;
      }
    }

    &[data-theme="toolbar"] input {
      @apply !py-2.5 !rounded-[44px] text-sm !leading-4 !border-content-dark-muted;

      &::placeholder {
        @apply text-content-light-muted;
      }
    }
  }

  [type="checkbox"].form-checkbox {
    @apply w-5 h-5 text-content-primary cursor-pointer rounded-sm;
    @apply border-content-dark-muted focus:border-content-primary focus:ring-0;
  }

  [type="radio"].form-radio {
    @apply w-5 h-5 text-content-primary cursor-pointer;
    @apply border-content-dark-muted focus:border-content-primary focus:ring-0;
  }

  .form-control {
    @apply form-field bg-content-light-1 text-content-dark-1 w-full block rounded-lg border text-para-md px-4 py-2.5 focus:outline-none;
    @apply border-content-dark-muted focus:border-content-primary focus:ring-content-primary;
  }
}
