[data-component="data-table"] {
  @apply bg-white;

  th,
  td {
    @apply px-4 py-3 text-left whitespace-nowrap;
  }

  th {
    @apply text-xs uppercase tracking-wider;
  }

  td {
    @apply text-sm;
  }

  tbody tr:hover,
  tbody tr:nth-child(even):hover {
    @apply bg-green bg-opacity-[0.05];
    & td {
      @apply text-black;
    }
  }

  tbody tr.selected {
    @apply bg-green bg-opacity-10 !important;
    & td {
      @apply text-black;
    }
  }
}
