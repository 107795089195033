@layer components {
  [data-coco][data-component="cb-blank-slate"] {
    @apply p-10 rounded-2xl border border-dashed border-content-dark-muted bg-content-light-1;

    .blank-slate-content {
      @apply max-w-2xl;
      /* text-align: center; */
      margin-inline: auto;
    }

    .blank-slate-actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .blank-slate-content + .blank-slate-actions {
      @apply mt-6;
    }
  }
}
