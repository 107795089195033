[data-partial="public/books/book"] {
  position: relative;

  .book-link {
    position: absolute;
    inset: 0;
  }

  .book-cover {
    background-size: cover;
    border: 1px solid var(--coco-colors-gray-transparent-50);
    aspect-ratio: 16 / 9;
    overflow: hidden;
    @apply bg-coco-gray-200 rounded-lg;
  }

  .book-actions {
    position: relative;
  }

  .book-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @apply pt-2.5;
  }

  .book-name {
    font-weight: 600;
    @apply text-label-sm truncate-2-lines leading-tight;
  }

  .book-metadata {
    color: var(--coco-colors-gray-500);
    @apply text-para-xs;

    span {
      position: relative;

      & + span {
        @apply ml-1.5 pl-1.5;

        &:before {
          content: "・";
          font-size: 18px;
          position: absolute;
          left: -10px;
        }
      }
    }

    .book-metadata-label {
      display: inline;
    }
  }
}
