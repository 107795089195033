@layer components {
  [data-coco][data-component="cb-plan-card"] {
    @apply @container border border-gray-transparent-200 rounded-2xl bg-background-light-1 shadow-md relative;
    min-width: 192px;

    .plan-container {
      @apply px-5 py-6 space-y-5;
    }

    .plan-badge {
      @apply absolute top-0 left-5 -translate-y-1/2;
    }

    .plan-promo {
      @apply label-sm text-content-primary;
    }

    .plan-qualifier {
      @apply para-sm text-content-dark-3;
    }

    .plan-header {
      @apply space-y-1;
    }

    .plan-name {
      @apply branded-heading-4;
    }

    .plan-price {
      @apply subheading-4;
    }

    .plan-info * {
      @apply para-sm;
    }

    .plan-benefits {
      @apply space-y-4 py-5 border-t border-coco-gray-300;
    }

    .plan-benefit {
      @apply label-sm flex;

      .coco-icon {
        @apply text-content-primary mr-1 mt-0.5;
      }
    }

    .plan-benefit-detail {
      @apply flex-grow;
    }

    .plan-footer {
      @apply !mt-0;
    }

    &[data-variant="active"] {
      @apply border-background-primary bg-background-primary-light;
    }

    &[data-variant="selected"] {
      @apply border-background-primary;
    }

    &[data-variant="negated"] {
      @apply border-content-dark-muted bg-background-light-3 shadow-none;

      .plan-benefit-detail {
        @apply text-content-dark-muted;

        text-decoration: line-through;
      }

      .plan-benefit .coco-icon {
        @apply text-content-dark-muted;
      }
    }

    @container (min-width: 230px) {
      .plan-container {
        @apply px-6 pt-8 space-y-6;
      }

      .plan-badge {
        @apply left-6;
      }

      .plan-name {
        @apply branded-heading-3;
      }

      .plan-benefits {
        @apply py-6;
      }
    }

    @container (min-width: 280px) {
      .plan-container {
        @apply px-10;
      }

      .plan-badge {
        @apply left-10;
      }

      .plan-name {
        @apply branded-heading-2;
      }

      .plan-price {
        @apply subheading-3;
      }

      .plan-info * {
        @apply para-md;
      }

      .plan-benefits {
        @apply space-y-5;
      }

      .plan-benefit {
        @apply label-md;
      }
    }
  }
}
