.section-group {
  .section-group-header {
    display: flex;
    align-items: center;
    min-height: 38px;
    @apply mb-2;
  }

  .section-group-title {
  }

  .section-group-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    @apply gap-x-2;
  }
}
