[data-partial="folders/list"] {
  @apply @container;
  position: relative;

  .list-items {
    @apply grid grid-cols-1 @md:grid-cols-2 @3xl:grid-cols-3 @5xl:grid-cols-4 @7xl:grid-cols-5 @md:gap-x-4 @md:gap-y-5;
  }

  .list-blank-state {
    display: contents;
  }

  &[data-size="sm"] [data-partial="folders/folder"] {
    @apply folder-sm @md:folder-card;
  }

  &[data-size="lg"] {
    .list-items {
      @apply gap-y-2 @md:gap-5;
    }

    [data-partial="folders/folder"] {
      @apply folder-lg @md:folder-card;
    }
  }
}
