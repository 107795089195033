@layer components {
  [data-coco][data-component="cb-import-box"] {
    position: relative;

    .import-box-info {
      position: absolute;
      top: -1.75rem;
      right: 0.1rem;
      z-index: 100;
    }

    .import-box-counter {
      position: absolute;
      font-weight: 600;
      @apply text-label-sm text-content-primary -bottom-6 right-0;
    }

    &.empty {
      .import-box-counter {
        @apply text-content-dark-1;
      }
    }

    &.error {
      textarea {
        @apply border-content-negative focus:border-content-negative focus:ring-content-negative;
      }

      .import-box-counter {
        @apply text-content-negative;
      }
    }
  }
}
