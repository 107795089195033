@layer components {
  [data-component="clip-mini-metric"] {
    min-height: 24px;
    @apply text-gray-600 inline-flex items-center;

    & .coco-icon {
      @apply mr-2 relative -mt-0.5 group-hover:text-accent !flex;
    }

    &.clip-mini-metric-na {
      @apply opacity-30;
    }

    &.clip-mini-metric-na .coco-icon {
      @apply group-hover:text-gray-600;
    }

    & [data-component="loading-spinner"] {
      @apply opacity-50 relative mr-0.5 -mt-0.5;
    }
  }
}
