@layer components {
  [data-component="image-fitted"] {
    img {
      @apply object-cover object-top absolute inset-0 w-full h-full;
    }

    &.fit-contain img {
      @apply object-contain;
    }

    &.fit-center img {
      @apply object-center;
    }
  }
}
