#app {
  .layout-wrapper {
    display: contents;
  }

  .layout-main {
    @apply grid overflow-hidden absolute inset-0 w-screen;
    height: var(--app-height);
    --sidebar-nav-width: 0;

    grid-template-areas: "body";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    &.with-header {
      grid-template-areas: "header" "body" "banner";
      grid-template-rows: min-content 1fr min-content;

      &.with-sidebar-nav {
        grid-template-areas: "header" "body" "banner" "sidebar";
        grid-template-rows: min-content 1fr min-content min-content;
      }
    }

    &.with-sidebar-nav {
      --snackbar-bottom-offset: theme(spacing.16);
    }

    &:not(.with-header) {
      grid-template-areas: "body" "banner";
      grid-template-rows: 1fr min-content;
    }

    .layout-banner {
      @apply w-screen z-10;
      grid-area: banner;
      height: min-content;
    }

    .layout-header {
      @apply w-screen z-10;
      grid-area: header;
      height: min-content;
    }

    .layout-body {
      @apply relative z-0 h-full overflow-y-auto scroll-smooth bg-background-light-2;
      grid-area: body;
    }

    .layout-sidebar-nav {
      @apply scroll-smooth;
      scrollbar-width: none;
      grid-area: sidebar;
      height: min-content;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media screen(sm) {
      .layout-sidebar-nav {
        @apply h-full overflow-auto;
        width: min-content;

        > * {
          @apply h-full;
        }
      }

      grid-template-areas: "body";
      grid-template-rows: 1fr;

      &.with-sidebar-nav {
        --snackbar-bottom-offset: theme(spacing.10);
      }

      &.with-header {
        grid-template-areas: "banner" "header" "body";
        grid-template-rows: min-content min-content 1fr;
        grid-template-columns: 1fr;

        &.with-sidebar-nav {
          grid-template-areas: "banner banner" "header header" "sidebar body";
          grid-template-rows: min-content min-content 1fr;
          grid-template-columns: min-content 1fr;
        }
      }
    }

    @media screen(sm) and screen(max-xl) {
      --sidebar-nav-width: theme(width.16);
    }

    @media screen(xl) {
      --sidebar-nav-width: theme(width.24);
    }

    @media screen(letterbox) {
      --sidebar-nav-width: theme(width.16);
    }
  }
}
