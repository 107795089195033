[data-partial="books/sections/section"] {
  position: relative;
  @apply bg-background-light-1 p-8 pt-4 shadow-md border border-gray-transparent-100 rounded-2xl @container cursor-grab;

  .section-header {
    display: flex;
    align-items: start;
    min-width: 1%;
    min-height: 46px;
    @apply mb-6;
  }

  .section-title {
    display: block;
    @apply pt-2 space-y-1;
    min-width: 1%;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  .section-drag-handle {
    @apply -ml-1.5 mr-2;
    cursor: grab;
    display: none;
  }

  @container (min-width: 600px) {
    .section-header {
      align-items: center;
    }

    .section-title {
      display: flex;
      align-items: center;
      @apply gap-2 pt-0  space-y-0;
    }

    .section-drag-handle {
      display: block;
    }

    .section-loader-text {
      display: block;
    }
  }

  .section-label {
    @apply heading-5 truncate;
    display: block;
  }

  .section-summary {
    @apply text-label-md text-content-dark-muted pr-1;
    white-space: nowrap;
    &::before {
      content: "(";
    }
    &::after {
      content: ")";
    }
  }

  .section-toolbar {
    margin-left: auto;
    .button-group-buttons {
      @apply gap-0;
    }
  }

  .section-body {
    width: 100%;
  }

  .section-actions {
    @apply mt-4 ml-auto;
  }

  .section-blank-state {
    display: flex;
    @apply mb-6 gap-x-6;

    h5 {
      @apply text-subheading-4 font-semibold;
    }

    .coco-link {
      @apply text-sm;
    }
  }

  .section-blank-state-thumbnail {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    flex: none;
    @apply rounded-lg bg-coco-gray-100;

    @container (min-width: 600px) {
      & {
        display: flex;
      }
    }
  }

  .section-preview,
  .section-placeholder {
    display: flex;
    flex-wrap: wrap;
    height: 120px;
    width: fit-content;
    align-items: start;
    overflow: hidden;
    @apply gap-x-4 gap-y-0;

    &:hover {
      cursor: pointer;
    }
  }

  .section-placeholder-item {
    @apply rounded-lg bg-coco-gray-100 animate-pulse;
    width: 80px;
    height: 80px;
    margin-top: 12px;
    margin-bottom: 28px;
  }

  @container (min-width: 450px) {
    .section-body {
      display: grid;
      grid-template-columns: 1fr min-content;
      @apply gap-4;
      align-items: center;
      width: 100%;
    }

    .section-blank-state {
      @apply mb-0;
    }

    .section-actions {
      margin-top: 0;
      padding-left: 80px;
    }
  }

  .section-thumbnail {
    @apply rounded-lg;
  }

  .section-slide {
    .section-thumbnail {
      width: 180px;
      height: 120px;
      aspect-ratio: 180 / 120;
      overflow: hidden;

      [data-component="cb-section-thumbnail"] {
        @apply rounded-lg;
        overflow: hidden;
      }

      figcaption {
        display: none;
      }
    }
  }

  .section-clip {
    height: 120px;
    padding-top: 12px;
    .section-thumbnail {
      width: 80px;

      [data-component="cb-clip-thumbnail"] {
        @apply rounded-lg;
        overflow: hidden;
        @apply mb-1;
      }

      figcaption {
        @apply text-para-xs truncate;
        width: 100%;
      }
    }
  }

  &[data-hidden="true"] {
    .section-preview,
    .section-label {
      opacity: 0.5;
    }
  }
}
