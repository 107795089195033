@layer components {
  [data-coco][data-component="cb-slide-thumbnail"] {
    width: 100%;
    overflow: hidden;
    position: relative;
    @apply bg-coco-gray-100;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
      display: block;
    }

    .slide-thumbnail-fallback {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .coco-icon {
        width: 15%;
        height: 15%;
        min-width: 20px;
        min-height: 20px;
      }

      .slide-thumbnail-loading-icon {
        opacity: 0.7;
      }

      .slide-thumbnail-missing {
        width: 50%;
        min-width: 50px;
        max-width: 100px;
      }
    }
  }
}
