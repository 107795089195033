[data-partial="upgrade-prompt-banner"] {
  @apply @container;

  .wrapper {
    position: relative;
    background-color: rgba(17, 30, 43, 0.0816599);
    border-radius: 0.75rem;
  }

  .media {
    z-index: 1;
    position: relative;
  }

  .gradient {
    position: absolute;
    inset: 0;
    z-index: 0;
    pointer-events: none;
  }

  .gradient-web {
    background: linear-gradient(
      rgba(17, 24, 39, 0) 80%,
      rgba(17, 25, 39, 0.0086472) 81.33%,
      rgba(17, 27, 41, 0.03551) 82.67%,
      rgba(17, 30, 43, 0.0816599) 84%,
      rgba(16, 35, 46, 0.147411) 85.33%,
      rgba(16, 41, 51, 0.231775) 86.67%,
      rgba(16, 48, 56, 0.331884) 88%,
      rgba(15, 56, 61, 0.442691) 89.33%,
      rgba(15, 65, 67, 0.557309) 90.67%,
      rgba(14, 73, 72, 0.668116) 92%,
      rgba(14, 80, 77, 0.768225) 93.33%,
      rgba(14, 86, 82, 0.852589) 94.67%,
      rgba(13, 91, 85, 0.91834) 96%,
      rgba(13, 94, 87, 0.96449) 97.33%,
      rgba(13, 96, 89, 0.991353) 98.67%,
      rgba(13, 97, 89) 100%
    );
  }

  .gradient-print {
    background: linear-gradient(rgba(17, 24, 39, 0) 50%, rgba(13, 97, 89) 80%);
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .banner {
    @apply -mt-px;
    @apply px-6;
    @apply pb-6;
    z-index: 2;
    color: var(--coco-colors-content-light-1);
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .banner-absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
  }

  .banner-relative {
    position: relative;
    background-color: var(--coco-colors-green-700);
  }

  h1 {
    @apply mb-1;
    @apply heading-6;
  }

  .copy {
    p {
      @apply text-xs;
    }

    .upgrade-prompt {
      @apply mt-2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
        @apply mb-1;
      }

      .upgrade-button {
        pointer-events: auto;
      }
    }
  }

  @container (min-width: 320px) {
    h1 {
      @apply heading-5;
    }

    .copy {
      p {
        @apply text-sm;
      }

      .upgrade-prompt {
        @apply mt-4;
      }
    }
  }

  @container (min-width: 460px) {
    h1 {
      @apply heading-4;
    }

    .copy {
      display: flex;
      justify-content: space-between;

      p {
        @apply w-3/4;
      }

      .upgrade-prompt {
        @apply ml-4;
        @apply mt-0;

        p {
          @apply mb-2;
          width: auto;
          text-align: center;
        }

        .upgrade-button {
          @apply m-0;
        }
      }
    }
  }
}
