[data-controller~="metrics-summary-palette"] [data-metric-selected="true"] {
  @apply bg-opacity-100 border-green shadow-lg;
}

[data-controller~="metrics-summary-palette"] [data-metric-selected="false"] {
  @apply bg-opacity-50 text-gray-800;
}

[data-controller~="metrics-summary-palette"] .select-toggle:not(:checked) + span .select-text {
  @apply inline-block;
}

[data-controller~="metrics-summary-palette"] .select-toggle:not(:checked) + span .unselect-text {
  @apply hidden;
}

[data-controller~="metrics-summary-palette"] .select-toggle:indeterminate + span .select-text,
[data-controller~="metrics-summary-palette"] .select-toggle:checked + span .select-text {
  @apply hidden;
}

[data-controller~="metrics-summary-palette"] .select-toggle:indeterminate + span .unselect-text,
[data-controller~="metrics-summary-palette"] .select-toggle:checked + span .unselect-text {
  @apply inline-block;
}

[data-controller~="metrics-summary-palette"] .select-toggle:indeterminate {
  border: red !important;
}
