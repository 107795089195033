@layer components {
  [data-component="front-matter-block"] {
    @apply grid grid-cols-12 gap-2 lg:gap-4 xl:gap-6;

    & .front-matter-slide {
      @apply col-span-4;
    }

    & .front-matter-slide:nth-child(n + 4) {
      @apply col-span-3;
    }
  }
}
