[data-partial="public-books-section"] {
  display: block;

  .section-header {
    display: flex;
    align-items: end;
    color: var(--coco-colors-content-dark-1);
    @apply mb-6;
  }

  .section-title {
    font-weight: 600;
    @apply tracking-tight text-2xl md:text-3xl lg:text-4xl;
  }

  .section-metadata {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: auto;
    @apply pl-8 md:pl-24 xl:pl-40;

    .coco-icon {
      @apply !text-accent mr-2;
    }
  }

  .section-items-count {
    text-transform: uppercase;
    font-weight: 700;
    @apply tracking-wide text-sm lg:text-base;
  }

  .section-items {
    display: grid;
    @apply grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4;

    > *:not(:last-child) {
      &:nth-child(1) {
        display: block;
      }

      &:nth-child(2) {
        display: none;
        @apply md:block;
      }

      &:nth-child(3) {
        display: none;
        @apply lg:block;
      }

      &:nth-child(4) {
        display: none;
        @apply xl:block;
      }
    }
  }

  .section-slide {
    border: 1px solid;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    position: relative;
    background-color: var(--coco-colors-background-light-1);
    @apply border-gray-transparent-100 rounded-xl;

    [data-component="cb-slide-thumbnail"] {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  .section-clip > div {
    height: 100%;
    box-shadow: none !important;
  }

  .section-signpost {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    border: 1px solid;
    cursor: pointer;
    height: 100%;
    background-color: var(--coco-colors-background-light-2);
    @apply border-gray-transparent-100 rounded-xl transition;

    > div {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      @apply p-4;
    }
  }

  .section-signpost:only-child {
    aspect-ratio: 1 / 1;
  }

  .section-signpost-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--coco-colors-background-light-1);
    @apply rounded-full w-16 h-16 transition;

    .coco-icon {
      @apply !text-accent transition !w-7 !h-7;
    }
  }

  .section-signpost-label {
    max-width: 180px;
    color: var(--coco-colors-content-dark-1);
    @apply mt-4;

    strong {
      display: block;
    }
  }

  .section-items:hover {
    .section-signpost {
      background-color: var(--coco-colors-background-light-3);
      border-color: var(--coco-colors-gray-300);
      @apply shadow-lg;
    }

    .section-signpost-icon {
      @apply bg-accent;

      .coco-icon {
        color: var(--coco-colors-content-light-1) !important;
      }
    }
  }
}
