@layer components {
  [data-component="clip-card-details"] {
    @apply flex items-start;

    & .clip-card-title-block {
      @apply leading-none block overflow-hidden;
    }

    & .clip-card-title {
      @apply font-bold text-gray-1000 text-sm xl:text-base leading-none truncate;
    }

    & .clip-card-subtitle {
      @apply block text-gray-700 text-sm truncate-2-lines print:truncate-1-lines leading-tight mt-0.5;
    }

    & .clip-card-category {
      @apply flex-none ml-auto opacity-70;
    }
  }
}
