@layer utilities {
  .aspect-box {
    position: relative;
  }

  .aspect-box > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
