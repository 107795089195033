@layer components {
  [data-coco][data-component="cb-plan-confirmation"] {
    @apply @container;
    max-width: 37rem;

    .plan-confirmation-grid {
      @apply grid gap-4;
    }

    .plan-confirmation-new-plan {
      order: 1;
    }

    .plan-confirmation-old-plan {
      @apply hidden;
    }

    .plan-confirmation-secondary-action {
      order: 3;
    }

    .plan-confirmation-primary-action {
      order: 2;
    }

    @container (min-width: 420px) {
      [data-component="cb-plan-card"] {
        @apply h-full;
      }

      .plan-confirmation-grid {
        @apply gap-8 grid-cols-2;
      }

      .plan-confirmation-new-plan {
        order: 2;
      }

      .plan-confirmation-old-plan {
        @apply block;
        order: 1;
      }

      .plan-confirmation-secondary-action {
        order: 3;
      }

      .plan-confirmation-primary-action {
        order: 4;
      }
    }
  }
}
