[data-partial="books/browser"] {
  @apply @container scroll-mt-4;

  .browser-header {
    align-items: center;
    justify-content: space-between;
    min-height: 38px;
    @apply mb-6 @2xl:flex;
  }

  .browser-title {
    display: flex;
    align-items: center;
    @apply gap-x-1.5 mb-4 @2xl:mb-0 text-heading-4;
  }

  .browser-books-count {
    color: var(--coco-colors-content-dark-muted);
    @apply text-subheading-4;
  }

  .browser-view-coverage {
    @apply text-label-sm ml-1;
  }

  .browser-loader {
    opacity: 0.3;
  }

  .browser-sorter {
    display: flex;
    align-items: center;
    @apply gap-x-4;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      @apply gap-2 @md:flex-row @md:items-center;
    }

    .search-input-wrapper,
    .browser-sorter-search {
      flex: none;
      width: 100%;
      @apply @md:w-[220px] @lg:w-[240px];
    }
  }

  .browser-items {
    display: grid;
    @apply grid-cols-1 @sm:grid-cols-2 @2xl:grid-cols-3 @5xl:grid-cols-4 @7xl:grid-cols-5;
    @apply gap-4 @4xl:gap-6;
  }

  .browser-footer {
    @apply mt-8;
  }
}
