:not([data-controller~="frame-feedback"]) {
  [data-frame-load-end="hide"],
  [data-frame-load-end="show"] {
    display: none;
  }
}
[data-controller~="frame-feedback"] {
  &[data-frame-state="loading"] [data-frame-load-start="hide"],
  [data-frame-load-end="hide"] {
    display: block;
    opacity: 0 !important;
    /* display: none !important; */
  }

  &[data-frame-state="loading"] [data-frame-load-start="show"],
  [data-frame-load-end="show"] {
    display: block;
    opacity: 1 !important;
    /* display: block !important; */
  }

  &[data-frame-state="loading"] [data-frame-load-start="enable"],
  [data-frame-load-end="enable"] {
    opacity: 1 !important;
    pointer-events: all !important;
  }

  &[data-frame-state="loading"] [data-frame-load-start="disable"],
  [data-frame-load-end="disable"] {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }
}
