[data-component="filestack-files"] {
  & [data-component-role="file"] {
    @apply flex items-center;
  }

  & [data-component-role="file-name"] {
    @apply truncate bg-no-repeat bg-left whitespace-nowrap bg-opacity-70 pr-6;
  }

  & [data-component-role="file-delete"] {
    @apply block cursor-pointer flex-none ml-auto bg-opacity-70 hover:bg-opacity-100;
    height: 18px;
    width: 18px;
    background-size: 18px 18px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-trash-2'%3E%3Cpolyline points='3 6 5 6 21 6'%3E%3C/polyline%3E%3Cpath d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'%3E%3C/path%3E%3Cline x1='10' y1='11' x2='10' y2='17'%3E%3C/line%3E%3Cline x1='14' y1='11' x2='14' y2='17'%3E%3C/line%3E%3C/svg%3E");
  }

  progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 100%;
    display: block;
    border-radius: 0;
    @apply bg-transparent h-2 transition-all;
  }

  progress[value]::-webkit-progress-bar {
    border-radius: 0;
    @apply bg-transparent;
  }

  progress[value]::-webkit-progress-value {
    border-radius: 0;
    @apply bg-content-primary;
  }

  progress[value]::-moz-progress-bar {
    border-radius: 0;
    @apply bg-content-primary;
  }
}
