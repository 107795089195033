@layer components {
  [data-coco][data-component="cb-sidebar-nav"] {
    @apply bg-background-dark-2 antialiased;

    @media screen(max-sm) {
      @apply app-sidebar-nav-horizontal;
    }

    @media screen(sm) and screen(max-xl) {
      @apply app-sidebar-nav-vertical-narrow;
    }

    @media screen(xl) {
      @apply app-sidebar-nav-vertical-wide;
    }

    @media screen(letterbox) {
      @apply app-sidebar-nav-vertical-narrow;
    }
  }
}

@layer utilities {
  .app-sidebar-nav-horizontal {
    @apply w-full h-14 grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    .nav-item {
      @apply flex items-stretch;

      &[data-show-on-mobile="false"] {
        @apply hidden;
      }
    }

    .nav-item-action {
      .nav-item-label {
        @apply hidden;
      }
    }
  }

  .app-sidebar-nav-vertical {
    @apply h-full;

    .nav-item-action {
      .nav-item-label {
        @apply text-content-light-1;
      }
    }
  }

  .app-sidebar-nav-vertical-narrow {
    @apply app-sidebar-nav-vertical;
    @apply w-16;

    .nav-item-action {
      [data-component="cb-sidebar-nav-item"] {
        @apply pb-2.5;
      }
    }
  }

  .app-sidebar-nav-vertical-wide {
    @apply app-sidebar-nav-vertical;
    @apply w-24;

    .nav-item-action {
      [data-component="cb-sidebar-nav-item"] {
        @apply pb-3;
      }
    }
  }
}
