@layer components {
  [data-coco][data-component="cb-folders-nav"] {
    @apply space-y-1;

    .folders-nav-item {
      @apply flex items-center text-sm rounded-lg;

      .item-count {
        @apply text-coco-gray-400;
      }

      .item-name {
        @apply mr-1;
      }

      .coco-icon {
        @apply mr-2 text-coco-gray-400 relative;
      }

      a {
        @apply flex items-center text-sm px-2 py-1 rounded-lg;
        min-width: 1%;
      }

      &.active {
        @apply bg-primary-50;

        .item-count {
          @apply text-coco-gray-900;
        }
      }
    }
  }
}
