@layer components {
  [data-coco][data-component="cb-sidebar-nav-menu"] {
    @apply bg-content-dark-2 antialiased shadow-md sm:rounded-r-md overflow-hidden divide-y divide-background-dark-3;
    width: min-content;

    [data-placement="bottom"] & {
      @apply rounded-b-md;
    }

    [data-placement="top"] & {
      @apply rounded-t-md;
    }

    .menu-item-link {
      .coco-link {
        @apply flex w-full items-center py-3 bg-content-dark-2 hover:bg-background-dark-1 text-content-dark-muted transition;
      }
    }

    .menu-item-label {
      @apply truncate min-w-0 text-content-light-1 transition-colors;
    }

    .menu-item-qualifier {
      @apply ml-auto label-sm flex-none pl-4;
    }

    .menu-item-select {
      select {
        @apply focus:ring-0 bg-transparent hover:bg-background-dark-1 active:bg-background-dark-1 text-content-light-2;
        @apply py-3 w-full border-0;
      }
    }

    .menu-item-action {
      @apply py-2;
    }

    .card {
      @apply grid gap-x-4 gap-y-1;
      grid-template-areas: "icon title" "icon text";
      grid-template-columns: min-content 1fr;

      .coco-icon {
        @apply mt-0.5 w-5 h-5 text-content-primary-inverse-vivid;
        grid-area: icon;
      }

      h4 {
        @apply text-content-light-1 font-bold heading-6;
        grid-area: title;
      }

      div {
        @apply para-sm;
        grid-area: text;
      }
    }

    @media screen(max-sm) {
      @apply app-sidebar-nav-menu-regular;
    }

    @media screen(sm) and screen(max-xl) {
      @apply app-sidebar-nav-menu-small;
    }

    @media screen(xl) {
      @apply app-sidebar-nav-menu-regular;
    }

    @media screen(letterbox) {
      @apply app-sidebar-nav-menu-small;
    }
  }
}

@layer utilities {
  .app-sidebar-nav-menu-small {
    .menu-item {
      width: 240px;
    }

    .menu-item-label {
      @apply text-sm;
    }

    .menu-item-select select {
      @apply text-sm;
    }

    .menu-item-link .coco-link,
    .menu-item-select select,
    .menu-item-action {
      @apply px-4;
    }

    &.nav-card-menu {
      .menu-item {
        width: 280px;
      }

      .coco-link {
        @apply px-4 py-4;
      }
    }
  }

  .app-sidebar-nav-menu-regular {
    .menu-item {
      width: 280px;
    }

    .menu-item-label {
      @apply text-base;
    }

    .menu-item-select select {
      @apply text-base;
    }

    .menu-item-link .coco-link,
    .menu-item-select select,
    .menu-item-action {
      @apply px-6;
    }

    &.nav-card-menu {
      .menu-item {
        width: 320px;
      }

      .coco-link {
        @apply px-4 py-4;
      }
    }
  }
}
