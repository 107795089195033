@layer components {
  [data-coco][data-component="cb-toggle"] {
    @apply flex-shrink-0 relative rounded-full inline-flex items-center justify-center w-10 cursor-pointer;
    height: 22px;

    .toggle-track-outer {
      @apply pointer-events-none absolute w-full rounded-md;
      height: 22px;
    }

    .toggle-track-inner {
      @apply bg-coco-gray-300 pointer-events-none absolute h-2 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200;
    }

    .toggle-marker {
      @apply pointer-events-none absolute left-0 inline-block h-5 w-5 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200 translate-x-0 bg-coco-gray-800;
      top: 1px;
    }

    &[aria-checked="true"] {
      .toggle-marker {
        @apply translate-x-5 bg-content-primary;
      }
    }
  }
}
