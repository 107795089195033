.clip-card[data-partial] {
  background: var(--coco-colors-background-light-1);
  border: 1px solid var(--coco-colors-gray-transparent-100);
  @apply rounded-lg;
  position: relative;
  display: grid;

  .clip-card-link {
    display: block;
    position: absolute;
    @apply inset-0;
  }

  .clip-card-actions {
    position: relative;
    grid-area: actions;
    display: flex;
    justify-content: end;

    .coco-button {
      color: var(--coco-colors-content-dark-3);

      &:hover {
        color: var(--coco-colors-content-dark-1);
      }
    }

    .clip-card-action-highlight {
      color: var(--coco-colors-amber-400);

      &:hover {
        color: var(--coco-colors-amber-500);
      }
    }

    .clip-card-action-remove {
      &:hover {
        color: var(--coco-colors-content-negative-dark);
      }
    }
  }

  .clip-card-thumbnail {
    grid-area: thumbnail;
    pointer-events: none;
  }

  .clip-card-metadata {
    position: relative;
    grid-area: metadata;
    max-width: 100%;
    pointer-events: none;
    line-height: 1;
  }

  .clip-card-description {
    overflow: hidden;
  }

  .clip-card-outlet-name {
    font-weight: bold;
    line-height: 1.2 !important;
    @apply text-sm truncate;
  }

  .clip-card-title {
    line-height: 1.2 !important;
    color: var(--coco-colors-gray-400);
    @apply text-sm mt-0.5;
  }

  .clip-card-outlet-icon {
    .coco-icon {
      color: var(--coco-colors-gray-400);
    }
  }

  .clip-card-pubdate {
    display: none;
  }

  .clip-card-metrics {
    grid-area: metrics;
  }

  /* Grid card layout */

  [data-list-display-format="grid"] & {
    height: 100%;
    grid-template-areas:
      "actions"
      "thumbnail"
      "metadata"
      "metrics";
    grid-template-rows: min-content min-content 1fr min-content;

    .clip-card-thumbnail {
      border-bottom: 1px solid var(--coco-colors-gray-transparent-100);
    }

    .clip-card-metadata {
      overflow: hidden;
    }

    .clip-card-outlet-name {
      @apply pr-6;
    }

    .clip-card-outlet-icon {
      position: absolute;
      top: 18px;
      @apply right-4;
    }

    .clip-card-actions {
      border-bottom: 1px solid var(--coco-colors-gray-transparent-100);
    }

    .clip-card-metadata {
      @apply px-4 pt-4;
    }

    .clip-card-metrics {
      display: flex;
      flex-wrap: nowrap;
      @apply gap-x-3 px-4 pt-4 pb-2;
    }

    .clip-card-metric[data-clip-card-show] {
      display: none;

      &[data-clip-card-show~="grid"] {
        display: inline-flex;
      }
    }

    &:not([data-variant="highlight"]) {
      .clip-card-metric {
        span {
          @apply !text-xs;
        }

        .coco-icon {
          width: 12px !important;
          height: 12px !important;
        }
      }
    }

    /* Highlight cards */

    &[data-variant="highlight"] {
      grid-template-areas:
        "thumbnail"
        "metadata"
        "metrics";
      grid-template-rows: min-content 1fr min-content;

      .clip-card-actions {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border-bottom: 0;
      }

      .clip-card-thumbnail {
        overflow: hidden;
        @apply rounded-t-lg;
      }

      .clip-card-outlet-name,
      .clip-card-title {
        @apply @lg:text-base;
      }

      .clip-card-title {
        @apply @lg:mt-1;
      }

      .clip-card-metadata,
      .clip-card-metrics {
        @apply @lg:px-6 @lg:pt-6;
      }

      .clip-card-metrics {
        @apply @lg:pb-4;
      }
    }
  }

  /* List card layout */

  [data-list-display-format="list"] & {
    grid-template-areas: "thumbnail metadata metrics actions";
    grid-template-columns: min-content 1fr min-content min-content;
    align-items: center;
    min-height: 68px;
    @apply gap-x-2 py-2 pr-2;

    .clip-card-thumbnail {
      width: 60px;
      overflow: hidden;
      @apply rounded-lg;

      @apply @2xl:w-[80px];
    }

    .clip-card-metadata {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr min-content;
      @apply pl-2;
    }

    .clip-card-description {
      margin-block: auto;
    }

    .clip-card-pubdate {
      white-space: nowrap;
      color: var(--coco-colors-gray-400);
      margin-block: auto;
      @apply @2xl:block text-sm pr-2 pl-3;
    }

    .clip-card-outlet-icon {
      position: absolute;
      z-index: 2;
      background-color: var(--coco-colors-background-light-1);
      @apply -left-5 -top-1 @2xl:-top-2 p-1 rounded-full;
    }

    .clip-card-metrics {
      width: 320px;
      display: none;
      grid-template-columns: repeat(4, 1fr);

      @apply @4xl:grid;
    }

    .clip-card-metric {
      border-left: 1px solid var(--coco-colors-gray-transparent-100);
      @apply px-2;

      [data-component="clip-mini-metric"] {
        visibility: hidden;
      }

      &[data-clip-card-show~="list"] {
        [data-component="clip-mini-metric"] {
          visibility: visible;
        }
      }

      &:last-child {
        border-right: 1px solid var(--coco-colors-gray-transparent-100);
      }
    }
  }

  /* Draggable cards */

  .orderable-items-list [data-controller~="sortable-list"] & {
    cursor: grab;
    transition: all 0.1s ease-in-out;
    @apply shadow;

    &:hover {
      @apply shadow-lg;
    }
  }

  /* Contained within an item wrapper with selection checkbox */

  .orderable-items-list[data-list-display-format="grid"] .orderable-item & {
    & + .orderable-item-selector {
      @apply py-2 px-2 top-0.5 left-0;

      .orderable-item-checkbox {
        @apply w-5 h-5;
      }
    }

    &[data-variant="highlight"] {
      & + .orderable-item-selector {
        background-color: var(--coco-colors-background-light-1);
        top: 1px;
        left: 1px;

        @apply rounded-tl-lg rounded-br-lg;
      }

      .clip-card-action-highlight {
        background-color: var(--coco-colors-background-light-1);
        border-radius: 0;
        @apply rounded-tr-lg rounded-bl-lg;
      }
    }
  }

  .orderable-items-list[data-list-display-format="list"] .orderable-item & {
    padding-left: 38px;

    & + .orderable-item-selector {
      @apply py-6 px-2.5 top-1/2 -translate-y-1/2 left-0;

      .orderable-item-checkbox {
        @apply w-5 h-5;
      }
    }
  }
}
