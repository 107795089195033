[data-component="modal"] {
  z-index: 10000 !important;
}

[data-component="modal"] [role="dialog"] {
  max-height: calc(100vh - 4rem);
}

[data-component="modal"].modal-sm [role="dialog"],
[data-component="modal"].modal-sm [role="external-close"] {
  @apply max-w-sm;
}

[data-component="modal"].modal-md [role="dialog"],
[data-component="modal"].modal-md [role="external-close"] {
  @apply max-w-2xl;
}

[data-component="modal"].modal-lg [role="dialog"],
[data-component="modal"].modal-lg [role="external-close"] {
  @apply max-w-4xl;
}

[data-component="modal"].modal-xl [role="dialog"],
[data-component="modal"].modal-xl [role="external-close"] {
  @apply max-w-6xl;
}

[data-component="modal"].modal-full [role="dialog"],
[data-component="modal"].modal-full [role="external-close"] {
  @apply max-w-screen-xxl;
}
