@layer components {
  .litepicker {
    z-index: 10100 !important;
    margin-top: 2px;
  }

  .litepicker .month-item-name,
  .litepicker .month-item-year {
    font-size: 14px;
    padding: 1px 25px 1px 6px !important;
    border-radius: 5px;
    background-position: right 0.4rem center;
    background-size: 1em 1em;
  }
}
