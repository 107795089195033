@import "tom-select/dist/css/tom-select.default.css";

.ts-wrapper {
  border: 0 !important;
  position: relative !important;
  padding: 0 !important;
}

.ts-control {
  @apply text-content-dark-1 w-full block rounded-lg border text-para-md px-4 py-2.5 focus:outline-none;
  @apply border-content-dark-muted focus:border-content-primary focus:ring-content-primary;

  height: 46px;
  background: #fff !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%23cfd1d3%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e") !important;
  background-position: right 0.5rem center !important;
  background-repeat: no-repeat !important;
  background-size: 1.5em 1.5em !important;
  padding-right: 2.5rem !important;

  cursor: pointer !important;

  outline: none !important;

  &::after {
    display: none !important;
  }
}

.dropdown-active .ts-control,
.plugin-dropdown_input.focus.dropdown-active .ts-control {
  @apply !rounded-lg;
  @apply !border-content-primary ring-content-primary ring-1;
}

.ts-dropdown {
  font-size: 16px !important;
  margin-top: 2px !important;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important; */
  overflow: hidden;
  text-align: left !important;

  @apply rounded-lg shadow-xl !important;

  & .option {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

.dropdown-input {
  outline: none !important;
}

.ts-control .item {
  white-space: nowrap;
}

[data-controller="modal"] .ts-dropdown {
  position: fixed;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  font-size: 16px !important;
  margin-top: 2px !important;
  width: calc(100% - 4rem) !important;
  transform: translateY(46px);
}

[data-controller="modal"] .grid-cols-2.gap-12 .ts-dropdown {
  width: calc(50% - 3.5rem) !important;
}
