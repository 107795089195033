@layer components {
  [data-coco][data-component="cb-faqs"] {
    @apply @container;

    .faqs-entries {
      @apply space-y-6;
    }

    .faqs-entry {
      break-inside: avoid;
    }

    .faqs-question {
      @apply branded-heading-6 mb-2;
    }

    .faqs-answer {
      @apply para-md space-y-4;

      a[href] {
        @apply link-primary;
      }
    }

    @container (min-width: 700px) {
      .faqs-entries {
        columns: 2;
        column-gap: 2rem;
      }
    }

    @container (min-width: 900px) {
      .faqs-entries {
        column-gap: 3rem;
      }
    }
  }
}
