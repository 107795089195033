.content-header {
  @apply px-app pt-3;
  width: 100%;

  .header-container {
    margin: auto;
    position: relative;
    @apply max-w-screen-xxl gap-x-4;

    .layout-main.with-sidebar-nav & {
      @apply max-w-full;
    }
  }

  .header-nav {
    display: flex;
    align-items: center;
    min-width: 1%;
    min-height: 46px;
  }

  .header-main {
    width: 100%;
    display: flex;
    align-items: center;
    min-width: 1%;
  }

  .header-content {
    width: 100%;
    line-height: 1;
    display: flex;
    align-items: center;
    min-width: 1%;
    @apply gap-x-4;
  }

  .header-icon {
    color: var(--coco-colors-content-dark-muted);
    width: 32px;
    height: 32px;
  }

  .header-title {
    @apply truncate;
  }

  .header-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
