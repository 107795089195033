[data-partial="breadcrumbs"] {
  display: flex;
  align-items: center;
  @apply text-label-sm;

  .breadcrumb-divider,
  .breadcrumb-link,
  .breadcrumb-text {
    user-select: none;
    white-space: nowrap;
  }

  .breadcrumb-divider {
    color: var(--coco-colors-content-dark-muted);
    display: block;
    @apply px-1;
  }

  .breadcrumb-link {
    color: var(--coco-colors-content-dark-1);
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb-text {
    font-style: normal;
    color: var(--coco-colors-content-dark-muted);
  }
}
