@layer components {
  [data-coco][data-component="cb-outlet-profile-card"] {
    @apply bg-background-light-1 shadow-md border border-gray-transparent-100 rounded-2xl @container;

    .outlet-card-inner {
      @apply px-6 py-4;
    }

    .outlet-overview,
    .outlet-identity {
      min-width: 1%;
      overflow: hidden;
    }

    .outlet-category {
      @apply text-para-xs text-content-dark-muted;
      text-transform: uppercase;
    }

    .outlet-name {
      @apply text-heading-3 line-clamp-2 pr-4;
      font-weight: 700;
      line-height: 30px;
    }

    .outlet-profile-actions {
      margin-left: auto;
      @apply mb-4;
    }

    .outlet-overview {
      display: flex;
      align-items: center;
      @apply gap-x-5;
    }

    .outlet-avatar {
      flex: none;
      width: 64px;
      height: 64px;
      border-radius: 100%;
      overflow: hidden;
      @apply border border-gray-transparent-100;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .outlet-card-body {
      @apply mt-8;
      width: 100%;
      display: flex;
      align-items: stretch;
    }

    .outlet-details {
      @apply pr-4;
    }

    .outlet-description {
      cursor: pointer;
      max-width: 800px;

      &:not(.expanded) {
        @apply line-clamp-2;
      }
    }

    .outlet-link {
      @apply mt-2;
    }

    .outlet-metrics {
      margin-left: auto;
      display: flex;
      align-items: stretch;
    }

    .outlet-metric {
      @apply pl-3 border-l border-coco-gray-300;
      white-space: wrap;

      &:not(:last-child) {
        @apply pr-3;
      }
    }

    .outlet-metric-count {
      @apply text-heading-5;
      font-weight: 700;
    }

    .outlet-metric-name {
      @apply text-para-sm text-content-dark-3 mt-1;
    }

    .outlet-metric-source {
      font-size: 10px;
      @apply gap-x-1.5 text-content-dark-muted mt-2;

      img {
        height: 11px;
        max-width: none;
      }
    }

    @container (min-width: 500px) {
      .outlet-card-inner {
        @apply px-8 py-6;
      }

      .outlet-card-header {
        display: flex;
        flex-direction: row-reverse;
        align-items: start;
      }

      .outlet-profile-actions {
        @apply mb-0;
      }

      .outlet-card-body {
        @apply mt-5;
      }

      .outlet-metric {
        @apply pl-6;

        &:not(:last-child) {
          @apply pr-6;
        }
      }
    }

    @container (min-width: 700px) {
      .outlet-avatar {
        width: 96px;
        height: 96px;
      }

      .outlet-details {
        @apply pr-10;
      }

      .outlet-metrics {
        flex: none;
      }

      .outlet-metric {
        white-space: nowrap;
      }

      .outlet-metric-source {
        display: flex;
        align-items: center;
      }
    }
  }
}
