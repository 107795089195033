@layer components {
  .clip-highlight-card {
    @apply relative bg-white border border-gray-400 rounded-2xl overflow-hidden transition shadow-sm flex flex-col h-full;
  }

  .clip-highlight-card-toolbar {
    @apply flex-none text-xs relative;
  }

  .clip-highlight-card-body {
    @apply px-6 py-4 border-t border-gray-400 h-full flex flex-col;
  }

  .clip-highlight-card-details {
    @apply flex items-start;
  }

  .clip-highlight-card-title-block {
    @apply leading-none block overflow-hidden;
  }

  .clip-highlight-card-title {
    @apply font-bold text-gray-1000 text-base xl:text-lg leading-none truncate;
  }

  .clip-highlight-card-subtitle {
    @apply block text-gray-700 text-sm xl:text-base truncate-2-lines xl:leading-tight mt-0.5 xl:mt-1;
  }

  .clip-highlight-card-cat {
    @apply flex-none ml-auto opacity-70;
  }

  .clip-highlight-card-metrics {
    @apply pt-4 mt-auto flex items-center space-x-4;
  }

  .clip-highlight-card-link {
    @apply absolute block inset-0;
  }
}
